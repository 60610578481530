.PageContent {
  margin-top: 70px;
  padding-top: 40px;
  height: auto;
  background-color: #eeeeee;
  min-height: calc(100vh - 113px);
  box-sizing: border-box;
}

.Container {
  width: 1140px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .Container {
    width: 100%;
    padding: 30px 0;
  }
}

@media (max-width: 800px) {
  .PageContent {
    padding-top: 0;
  }

  .PageContent iframe {
    width: -webkit-fill-available;
  }
}

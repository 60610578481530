.Person {
    display: inline-block;
    margin: 20px;
}

.Person_img {
    background: var(--dark-grey);
    padding: 10px 10px 0 10px;
    margin: 0 10px;
    display: inline-block;  
}

.Person_img img {
    /* cut the bottom padding of the img */
    vertical-align: bottom;
}

.Person_name {
    background: var(--yellow);
    clip-path: polygon(0 0, 94% 0, 100% 100%, 0 100%);
    word-wrap: break-word;
    padding: 10px 18px;
    position: relative;
    bottom: 150px;
    font-size: 23px;
    font-weight: bold;
    max-width: 270px; 
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Person_role {
    color: white;
    font-size: 18px;
}

.Person_details {
    background: var(--dark-grey);
    display: flex;
    flex-direction: column;
    color: white;
    padding: 45px 10px 10px 18px;
    text-align: left;
    height: 55px;
}

.Person_details span {
    display: flex;
    align-items: center;
    padding: 3px;
  }
  
.Person_details span svg {
    font-size: inherit;
    padding-right: 8px;    
}
.GetinqueryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GetinqueryFormField {
  min-width: 300px !important;
}

/* 
.GetinqueryFormField:last-of-type {
    min-width: 600px !important;   
}
 */

@media (max-width: 800px) {
  .GetinqueryFormField {
    min-width: 0 !important;
  }

  .GetinqueryFormField:last-of-type {
    min-width: 0 !important;
  }
}

.Button {
  font-size: 16px;
  font-weight: bold;
  background-color: var(--yellow);
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 7px 15px;
  text-transform: uppercase;
  margin: 20px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: green !important;
}

.ErrosMessage {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.DataUsage {
  margin-top: 20px;
  text-align: left;
  display: flex;
}
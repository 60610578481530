.IntroCards {
    display: flex;
    flex-direction: row;
    text-align: center;
}

@media (max-width: 800px) {
    .IntroCards {
        flex-direction: column; 
    }
}
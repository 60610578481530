.PageContentHeader {
  font-size: 28px;
  text-transform: uppercase;
  margin-top: 0;
  padding-bottom: 15px;  
  color: var(--header-grey);
}

.PageContentHeader::after {
  width: 60px;
  height: 5px;
  background-color: var(--yellow);
  display: block;
  content: "";
  margin: 15px auto 0 auto;
}

@media (max-width: 800px) {
  .PageContentHeader {
    margin-bottom: 0;
  }
}

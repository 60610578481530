@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --yellow: #ffc223;
  --light-grey: #78828a;
  --header-grey: #333333;
  --dark-grey: #1b2123;
}

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.PageHeader_PageHeader__2DrRD {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--dark-grey);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 1px 4px 14px rgba(8, 8, 8, 0.4);
}

.PageHeader_Container__3t1bx {
  display: flex;
  width: 1200px;
  margin: auto;
}

@media (max-width: 800px) {
  .PageHeader_DesktopOnly__3bAU0 {
    display: none;
  }
  .PageHeader_SideMenu__sGljw {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-self: center;
  }
}

.Logo_Logo__1j9hW img {
  width: 100%;
  height: 80px;
}

.Menu_Menu__boq4U {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  flex-flow: column;
}

@media (min-width: 801px) {
  .Menu_Menu__boq4U {
    flex-flow: row;
  }
}

.MenuItem_MenuItem__3yWyC {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.MenuItem_MenuItem__3yWyC a {
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.MenuItem_MenuItem__3yWyC a:hover,
.MenuItem_MenuItem__3yWyC a:active,
.MenuItem_MenuItem__3yWyC a.MenuItem_active__pweHR {
  color: var(--yellow);
}

@media (min-width: 801px) {
  .MenuItem_MenuItem__3yWyC {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .MenuItem_MenuItem__3yWyC a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    position: relative;
  }

  .MenuItem_MenuItem__3yWyC a:hover,
  .MenuItem_MenuItem__3yWyC a:active,
  .MenuItem_MenuItem__3yWyC a.MenuItem_active__pweHR {
    color: var(--yellow);
  }

  /*     .active {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }

    .MenuItem .active {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    } */

  .MenuItem_MenuItem__3yWyC a:after {
    background: var(--yellow) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 0%;
  }

  .MenuItem_MenuItem__3yWyC:hover a:after {
    width: 100%;
  }
}

.ContactMenu_ContactMenu__ODsbt {
  color: var(--light-grey);
  font-size: 11px;
  text-align: end;
  margin-top: 5px;
}

.ContactMenu_ContactMenu__ODsbt span {
  margin: 0 12px;
  display: inline-flex;
  align-items: center;
}

.ContactMenu_ContactMenu__ODsbt p {
  display: none;
}

.ContactMenu_ContactMenu__ODsbt span svg {
  font-size: inherit;
  padding-right: 4px;
  color: var(--yellow);
}

@media (max-width: 800px) {
  .ContactMenu_ContactMenu__ODsbt {
    display: none;
  }
}

.ContactMenu_ContactMenuColumn__3ZRNG {
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-align: start;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.ContactMenu_ContactMenuColumn__3ZRNG p {
  margin-top: 0;
  margin-left: 12px;
}

.ContactMenu_ContactMenuColumn__3ZRNG span {
  margin: 0 12px;
  display: flex;
  align-items: center;
  padding: 4px;
}

.ContactMenu_ContactMenuColumn__3ZRNG span svg {
  font-size: inherit;
  padding-right: 8px;
  color: var(--yellow);
}

@media (max-width: 800px) {
  .ContactMenu_ContactMenuColumn__3ZRNG {
    margin-bottom: 30px;
  }

  .ContactMenu_ContactMenuColumn__3ZRNG p {
    margin-left: 0;
  }

  .ContactMenu_ContactMenuColumn__3ZRNG span {
    margin: 0;
  }
}

.ToggleMenu_ToggleMenu__xA_0F {
  width: 40px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.ToggleMenu_ToggleMenu__xA_0F div {
  width: 90%;
  height: 3px;
  background-color: white;
}

@media (min-width: 801px) {
  .ToggleMenu_ToggleMenu__xA_0F {
    display: none;
  }
}

.MobileMenu_MobileMenu__WQMVj {
  position: absolute;
  background-color: var(--dark-grey);
  width: 100%;
  top: 80px;
  left: 0;
  display: none;
}

@media (min-width: 801px) {
  .MobileMenu_MobileMenu__WQMVj {
    display: none;
  }
}

.MobileMenu_Open__32pR8 {
  display: block;
}

.HomeButton_HomeButton__2JMb1 {
  margin-right: 30px;
}

.HomeButton_HomeButton__2JMb1 svg {
  color: white;
  height: 40px;
  width: 40px;
}

@media (min-width: 801px) {
  .HomeButton_HomeButton__2JMb1 {
    display: none;
  }
}

.HomeButton_active__2YxFp svg {
  color: var(--yellow);
}
.PageContent_PageContent__1ccBl {
  margin-top: 70px;
  padding-top: 40px;
  height: auto;
  background-color: #eeeeee;
  min-height: calc(100vh - 113px);
  box-sizing: border-box;
}

.PageContent_Container__21kY_ {
  width: 1140px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .PageContent_Container__21kY_ {
    width: 100%;
    padding: 30px 0;
  }
}

@media (max-width: 800px) {
  .PageContent_PageContent__1ccBl {
    padding-top: 0;
  }

  .PageContent_PageContent__1ccBl iframe {
    width: -webkit-fill-available;
  }
}

.SideBar_SideBar__12De6 {
  position: fixed;
  right: 0;
  top: 113px;
  width: 40px;
}

.SideBar_SideBar__12De6 svg {
  width: 30px;
  height: 30px;
  color: #d6d6d6;
}

.SideBar_Fb__3fSUF:hover svg {
  color: #4267b2;
}

.SideBar_Envelope__27U8E:hover svg {
  color: var(--header-grey);
}

@media (max-width: 1299px) {
  .SideBar_SideBar__12De6 {
    display: none;
  }
}

.PageFooter_PageFooter__1gyTA {
  background-color: var(--dark-grey);
  color: white;
  padding: 14px 0;
  font-size: 11px;
  display: flex;
  justify-content: center;
}

.FooterItem_FooterItem__11TF5 {
  padding: 0 15px;
  text-decoration: none;
  color: var(--light-grey);
  font-family: inherit;
}

.PageContentHeader_PageContentHeader__PkDMF {
  font-size: 28px;
  text-transform: uppercase;
  margin-top: 0;
  padding-bottom: 15px;  
  color: var(--header-grey);
}

.PageContentHeader_PageContentHeader__PkDMF::after {
  width: 60px;
  height: 5px;
  background-color: var(--yellow);
  display: block;
  content: "";
  margin: 15px auto 0 auto;
}

@media (max-width: 800px) {
  .PageContentHeader_PageContentHeader__PkDMF {
    margin-bottom: 0;
  }
}

.ImgGallery_ImageGallery__2CN5G {
  margin-bottom: 35px;
}

.NewsItem_NewsItem__1Fs7g {
  margin: 10px;
  text-decoration: none;
  font-family: inherit;
  color: var(--dark-grey);
}

.NewsItem_NewsItem__1Fs7g img {
  max-width: 280px;
}

.NewsItem_NewsItemHeader__25tlE {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0 5px;
}

.NewsItem_NewsItem__1Fs7g:last-child {
  margin-bottom: 30px;
}

.News_News__3svhn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.AdvantageItem_AdvantageItem__3uwt- {
  margin: 0 20px 20px 20px;
  max-width: 230px;
}

.AdvantageItem_AdvantageItem__3uwt- svg {
  font-size: 70px;
}

.AdvantageItem_AdvantageItemHeader__3U56V {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0 5px;
}

.AdvantageItem_AdvantageItemDesc__Jhx4B {
}

.Advantages_Advantages__2sq_4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.Home_Photos__2qqmJ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Home_Photo__3Psok {
    margin-bottom: 30px;
    max-height: 335px;
/*     max-width: 640px; */
    width: 100%;
}

/* .Photo:first-of-type {
    margin-right: 24px;
} */

@media (max-width: 665px) {
    .Home_Photo__3Psok {
      width: 100%;
      margin-bottom: 15px;
      height: auto;
    }

    .Home_Photo__3Psok:first-of-type {
        margin-right: 0;
    }
  }
.IntroCards_IntroCards__3kPlL {
    display: flex;
    flex-direction: row;
    text-align: center;
}

@media (max-width: 800px) {
    .IntroCards_IntroCards__3kPlL {
        flex-direction: column; 
    }
}
.IntroCard_IntroCard__2FG-7 {
    display: flex;
    width: 25%;
    padding: 5px;
}

.IntroCard_Arrow__3Ldqb {
    height: 300px;
}

.IntroCard_ArrowMobile__Qc1AK {
    height: 300px;
}

.IntroCard_Cardcontainer__9umKI {
    padding: 8px;
}

.IntroCard_Icon__PpEod {
    height: 60px;
    padding-right: 38px;
}

.IntroCard_Year__OZIce{
    font-weight: bold;
    font-size: 20px;
    padding: 15px 38px 15px 0;
}

.IntroCard_Text__2kC1R {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
}

.IntroCard_ArrowMobile__Qc1AK {
    display: none;
}

@media (max-width: 800px) {
    .IntroCard_IntroCard__2FG-7 {
        width: 100%;
        padding: 5px;    
        display: contents;
    }

    .IntroCard_Year__OZIce, .IntroCard_Icon__PpEod, Text {
        padding-right: 0;
        text-align: center;
    }
    
    .IntroCard_Arrow__3Ldqb {
        display: none;    
    }

    .IntroCard_ArrowMobile__Qc1AK {
        display: block;
        height: 40px;
        width: 100%;
        padding-bottom: 10px;
    }
}
.AimCards_AimCards__3bOQy {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 800px) {
    .AimCards_AimCards__3bOQy {
        flex-direction: column; 
    }
}
.AimCard_AimCard__19EnM {
    display: flex;
    width: 340px;
    padding: 5px;
}

.AimCard_CardHeaderContainer__1ISCx {
    display: flex;
    padding-bottom: 10px;
}


.AimCard_Shadow__1OTGp {
    height: 450px;
}

.AimCard_ShadowMobile__3mqHK {
    height: 300px;
}

.AimCard_Cardcontainer__q_qxh {
    padding: 8px;
}

.AimCard_Icon__2PKNL {
    height: 60px;
    padding-right: 25px;
}

.AimCard_CardHeader__sxOZF{
    font-weight: bold;
    font-size: 20px;   
    display: flex;
    align-items: center;
    text-align: left;
}

.AimCard_Text__3s-Kg ul {
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;  
    font-size: 15px;
    font-weight: 400;
}

.AimCard_Text__3s-Kg li {
    padding-bottom: 10px;
}

.AimCard_ShadowMobile__3mqHK {
    display: none;
}

@media (max-width: 800px) {
    .AimCard_AimCard__19EnM {
        width: 100%;
        padding: 5px;    
        display: contents;
    }

    .AimCard_CardHeader__sxOZF, .AimCard_Icon__2PKNL, Text {
        padding-right: 0;
        text-align: left;
    }

    .AimCard_Icon__2PKNL {
        padding-right: 25px;
    }
    
    .AimCard_Shadow__1OTGp {
        display: none;    
    }

    .AimCard_ShadowMobile__3mqHK {
        display: block;
        height: 40px;
        width: 100%;
        padding-bottom: 10px;
    }
}
.Intro_Intro__3fCU2 p {
  text-align: justify;
}

.Intro_Intro__3fCU2 span {
  text-align: left;
}

.Intro_Intro__3fCU2 ul {
  text-align: initial;
}

@media (max-width: 1200px) {
  .Intro_Text__3Y-9d {
    padding: 0 10px;
  }
}

.Intro_IntroBox__2eGjF {
  display: flex;
  width: 25%;
  padding: 5px;
}

.Intro_IntroBoxWrapper__3k5py {
  display: flex;
  flex-direction: row;
}
.ContactShop_ContactShop__2z6hq{margin:10px 0;width:100%}.ContactShop_ContactShoptitle__2fi8G{font-size:20px;font-weight:bold;margin-bottom:10px}.ContactShop_ContactsBtn__1tS1s{margin:10px 0;background:var(--yellow);width:50%;display:inline-block;font-weight:bold}.ContactShop_ContactsBtn__1tS1s a{text-decoration:none;color:#000}.ContactShop_ContactShopDetails__3SwCd{background-color:var(--dark-grey);box-sizing:border-box;display:flex;justify-content:space-evenly;padding:15px 0;margin:10px 0}@media(max-width: 800px){.ContactShop_ContactShopDetails__3SwCd{display:block;padding:15px}}.ContactShop_OpenText__2zy8F{color:#fff;font-weight:normal;text-align:left}.ContactShop_OpenText__2zy8F p{margin-top:0;display:flex;align-content:center}.ContactShop_OpenText__2zy8F td{padding-right:20px}.ContactShop_OpenText__2zy8F svg{width:25px;height:25px;padding-right:5px}.ContactShop_OpenText__2zy8F svg path{stroke:var(--yellow)}.ContactShop_ContactMenuColumn__2gZ-1{color:#fff;font-size:11px;font-weight:400;text-align:start;display:flex;flex-direction:column;font-size:16px}@media(max-width: 800px){.ContactShop_ContactMenuColumn__2gZ-1{margin-bottom:30px}}.ContactShop_ContactMenuColumn__2gZ-1 p{margin-top:0;margin-left:12px}@media(max-width: 800px){.ContactShop_ContactMenuColumn__2gZ-1 p{margin-left:0}}.ContactShop_ContactMenuColumn__2gZ-1 span{margin:0 12px;display:flex;align-items:center;padding:4px}@media(max-width: 800px){.ContactShop_ContactMenuColumn__2gZ-1 span{margin:0}}.ContactShop_ContactMenuColumn__2gZ-1 span svg{font-size:inherit;padding-right:8px;color:var(--yellow)}
.Contact_Contact__3uCVo {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

@media (max-width: 800px) {
    .Contact_Contact__3uCVo {
      flex-direction: column;
    }
}
.Getinquery_GetinqueryForm__3Uk2Y {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Getinquery_GetinqueryFormField__1i68h {
  min-width: 300px !important;
}

/* 
.GetinqueryFormField:last-of-type {
    min-width: 600px !important;   
}
 */

@media (max-width: 800px) {
  .Getinquery_GetinqueryFormField__1i68h {
    min-width: 0 !important;
  }

  .Getinquery_GetinqueryFormField__1i68h:last-of-type {
    min-width: 0 !important;
  }
}

.Getinquery_Button__3h80v {
  font-size: 16px;
  font-weight: bold;
  background-color: var(--yellow);
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 7px 15px;
  text-transform: uppercase;
  margin: 20px;
}

.Getinquery_MuiCheckbox-colorSecondary__1X94v.Getinquery_Mui-checked__1wClr {
  color: green !important;
}

.Getinquery_ErrosMessage__11ge0 {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.Getinquery_DataUsage__IVunB {
  margin-top: 20px;
  text-align: left;
  display: flex;
}
.ProvidersItems_ProvidersItems__1rVOW {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ProvidersItem_ProvidersItem__2P_FL {  
  margin: 10px;
  height: 120px;
  width: 250px;
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProvidersItem_ProvidersItem__2P_FL img {
  max-width: 200px;
  max-height: 200px;
}

@media (max-width: 1200px) {
  .DataUsage_Text__1XOFR {
      padding: 0 10px;
  }
}
.Person_Person__1nKwq {
    display: inline-block;
    margin: 20px;
}

.Person_Person_img__39RYr {
    background: var(--dark-grey);
    padding: 10px 10px 0 10px;
    margin: 0 10px;
    display: inline-block;  
}

.Person_Person_img__39RYr img {
    /* cut the bottom padding of the img */
    vertical-align: bottom;
}

.Person_Person_name__19u0k {
    background: var(--yellow);
    -webkit-clip-path: polygon(0 0, 94% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 94% 0, 100% 100%, 0 100%);
    word-wrap: break-word;
    padding: 10px 18px;
    position: relative;
    bottom: 150px;
    font-size: 23px;
    font-weight: bold;
    max-width: 270px; 
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Person_Person_role__13YqM {
    color: white;
    font-size: 18px;
}

.Person_Person_details___J1kz {
    background: var(--dark-grey);
    display: flex;
    flex-direction: column;
    color: white;
    padding: 45px 10px 10px 18px;
    text-align: left;
    height: 55px;
}

.Person_Person_details___J1kz span {
    display: flex;
    align-items: center;
    padding: 3px;
  }
  
.Person_Person_details___J1kz span svg {
    font-size: inherit;
    padding-right: 8px;    
}
.Articles_Articles__2dXn5 {
    text-align: justify;
}

.Articles_Logo__1XJEY {
    width: 100px;
}

.Articles_Photos__3YSiw {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Articles_Photo__1u222 {
    max-height: 400px;
    margin: 10px;
}

.Articles_Firstparagraph__1ZwK2 {
    font-weight: bold;
}

@media (max-width: 630px) {
    .Articles_Photo__1u222 {
      width: 100%;
      height: auto;
    }

    .Articles_Articles__2dXn5 {
        margin-right: 5px;
        margin-left: 5px;
    }
  }

.SideBar {
  position: fixed;
  right: 0;
  top: 113px;
  width: 40px;
}

.SideBar svg {
  width: 30px;
  height: 30px;
  color: #d6d6d6;
}

.Fb:hover svg {
  color: #4267b2;
}

.Envelope:hover svg {
  color: var(--header-grey);
}

@media (max-width: 1299px) {
  .SideBar {
    display: none;
  }
}

.MobileMenu {
  position: absolute;
  background-color: var(--dark-grey);
  width: 100%;
  top: 80px;
  left: 0;
  display: none;
}

@media (min-width: 801px) {
  .MobileMenu {
    display: none;
  }
}

.Open {
  display: block;
}

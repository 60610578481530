.ProvidersItem {  
  margin: 10px;
  height: 120px;
  width: 250px;
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProvidersItem img {
  max-width: 200px;
  max-height: 200px;
}

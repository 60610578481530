.ContactMenu {
  color: var(--light-grey);
  font-size: 11px;
  text-align: end;
  margin-top: 5px;
}

.ContactMenu span {
  margin: 0 12px;
  display: inline-flex;
  align-items: center;
}

.ContactMenu p {
  display: none;
}

.ContactMenu span svg {
  font-size: inherit;
  padding-right: 4px;
  color: var(--yellow);
}

@media (max-width: 800px) {
  .ContactMenu {
    display: none;
  }
}

.ContactMenuColumn {
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-align: start;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.ContactMenuColumn p {
  margin-top: 0;
  margin-left: 12px;
}

.ContactMenuColumn span {
  margin: 0 12px;
  display: flex;
  align-items: center;
  padding: 4px;
}

.ContactMenuColumn span svg {
  font-size: inherit;
  padding-right: 8px;
  color: var(--yellow);
}

@media (max-width: 800px) {
  .ContactMenuColumn {
    margin-bottom: 30px;
  }

  .ContactMenuColumn p {
    margin-left: 0;
  }

  .ContactMenuColumn span {
    margin: 0;
  }
}

.NewsItem {
  margin: 10px;
  text-decoration: none;
  font-family: inherit;
  color: var(--dark-grey);
}

.NewsItem img {
  max-width: 280px;
}

.NewsItemHeader {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0 5px;
}

.NewsItem:last-child {
  margin-bottom: 30px;
}

.IntroCard {
    display: flex;
    width: 25%;
    padding: 5px;
}

.Arrow {
    height: 300px;
}

.ArrowMobile {
    height: 300px;
}

.Cardcontainer {
    padding: 8px;
}

.Icon {
    height: 60px;
    padding-right: 38px;
}

.Year{
    font-weight: bold;
    font-size: 20px;
    padding: 15px 38px 15px 0;
}

.Text {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
}

.ArrowMobile {
    display: none;
}

@media (max-width: 800px) {
    .IntroCard {
        width: 100%;
        padding: 5px;    
        display: contents;
    }

    .Year, .Icon, Text {
        padding-right: 0;
        text-align: center;
    }
    
    .Arrow {
        display: none;    
    }

    .ArrowMobile {
        display: block;
        height: 40px;
        width: 100%;
        padding-bottom: 10px;
    }
}
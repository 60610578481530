.AimCard {
    display: flex;
    width: 340px;
    padding: 5px;
}

.CardHeaderContainer {
    display: flex;
    padding-bottom: 10px;
}


.Shadow {
    height: 450px;
}

.ShadowMobile {
    height: 300px;
}

.Cardcontainer {
    padding: 8px;
}

.Icon {
    height: 60px;
    padding-right: 25px;
}

.CardHeader{
    font-weight: bold;
    font-size: 20px;   
    display: flex;
    align-items: center;
    text-align: left;
}

.Text ul {
    padding-inline-start: 20px;  
    font-size: 15px;
    font-weight: 400;
}

.Text li {
    padding-bottom: 10px;
}

.ShadowMobile {
    display: none;
}

@media (max-width: 800px) {
    .AimCard {
        width: 100%;
        padding: 5px;    
        display: contents;
    }

    .CardHeader, .Icon, Text {
        padding-right: 0;
        text-align: left;
    }

    .Icon {
        padding-right: 25px;
    }
    
    .Shadow {
        display: none;    
    }

    .ShadowMobile {
        display: block;
        height: 40px;
        width: 100%;
        padding-bottom: 10px;
    }
}
.Intro p {
  text-align: justify;
}

.Intro span {
  text-align: left;
}

.Intro ul {
  text-align: initial;
}

@media (max-width: 1200px) {
  .Text {
    padding: 0 10px;
  }
}

.IntroBox {
  display: flex;
  width: 25%;
  padding: 5px;
}

.IntroBoxWrapper {
  display: flex;
  flex-direction: row;
}
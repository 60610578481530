.Articles {
    text-align: justify;
}

.Logo {
    width: 100px;
}

.Photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Photo {
    max-height: 400px;
    margin: 10px;
}

.Firstparagraph {
    font-weight: bold;
}

@media (max-width: 630px) {
    .Photo {
      width: 100%;
      height: auto;
    }

    .Articles {
        margin-right: 5px;
        margin-left: 5px;
    }
  }
.Photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Photo {
    margin-bottom: 30px;
    max-height: 335px;
/*     max-width: 640px; */
    width: 100%;
}

/* .Photo:first-of-type {
    margin-right: 24px;
} */

@media (max-width: 665px) {
    .Photo {
      width: 100%;
      margin-bottom: 15px;
      height: auto;
    }

    .Photo:first-of-type {
        margin-right: 0;
    }
  }
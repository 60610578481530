.HomeButton {
  margin-right: 30px;
}

.HomeButton svg {
  color: white;
  height: 40px;
  width: 40px;
}

@media (min-width: 801px) {
  .HomeButton {
    display: none;
  }
}

.active svg {
  color: var(--yellow);
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --yellow: #ffc223;
  --light-grey: #78828a;
  --header-grey: #333333;
  --dark-grey: #1b2123;
}

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.AdvantageItem {
  margin: 0 20px 20px 20px;
  max-width: 230px;
}

.AdvantageItem svg {
  font-size: 70px;
}

.AdvantageItemHeader {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0 5px;
}

.AdvantageItemDesc {
}

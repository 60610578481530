.PageHeader {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--dark-grey);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 1px 4px 14px rgba(8, 8, 8, 0.4);
}

.Container {
  display: flex;
  width: 1200px;
  margin: auto;
}

@media (max-width: 800px) {
  .DesktopOnly {
    display: none;
  }
  .SideMenu {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-self: center;
  }
}

.AimCards {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 800px) {
    .AimCards {
        flex-direction: column; 
    }
}
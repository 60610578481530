.ContactShop {
  margin: 10px 0;
  width: 100%;
}

.ContactShoptitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ContactsBtn {
  margin: 10px 0;
  background: var(--yellow);
  width: 50%;
  display: inline-block;
  font-weight: bold;
  a {
    text-decoration: none;
    color: black;
  }
}

.ContactShopDetails {
  background-color: var(--dark-grey);
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0;
  margin: 10px 0;
  @media (max-width: 800px) {
    display: block;
    padding: 15px;
  }
}
  
.OpenText {
  color: white;
  font-weight: normal;
  text-align: left;
  p {
    margin-top: 0;
    display: flex;
    align-content: center;
  }

  td {
    padding-right: 20px;
  }

  svg {
    width: 25px;
    height: 25px;
    padding-right: 5px;
    
    path {
      stroke: var(--yellow);
    }
  }
}

.ContactMenuColumn {
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-align: start;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
  
  p {
    margin-top: 0;
    margin-left: 12px;
    @media (max-width: 800px) {
      margin-left: 0;
    }
  }

  span {
    margin: 0 12px;
    display: flex;
    align-items: center;
    padding: 4px;
    @media (max-width: 800px) {
      margin: 0;
    }
    svg {
      font-size: inherit;
      padding-right: 8px;
      color: var(--yellow);
    }
  }
}
.MenuItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.MenuItem a {
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.MenuItem a:hover,
.MenuItem a:active,
.MenuItem a.active {
  color: var(--yellow);
}

@media (min-width: 801px) {
  .MenuItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .MenuItem a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    position: relative;
  }

  .MenuItem a:hover,
  .MenuItem a:active,
  .MenuItem a.active {
    color: var(--yellow);
  }

  /*     .active {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }

    .MenuItem .active {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    } */

  .MenuItem a:after {
    background: var(--yellow) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 0%;
  }

  .MenuItem:hover a:after {
    width: 100%;
  }
}
